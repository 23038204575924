import {Action, combineReducers} from '@ngrx/store';
import * as types from '../actionTypes';


const initialState = {
   
    cloudinaryCreds: null
}

export function cloudinaryReducer( state = initialState, action:any){
    switch(action.type) {
        
        case types.SET_CLOUDINARY_CREDS: 
        return {
            ...state,
            cloudinaryCreds: action.creds
        }
        default:
            return state;
    }
}