import {Action} from '@ngrx/store';
import * as actionTypes from '../actionTypes';


export const setCloudinaryCred = (creds)=>{
    return {
        type: actionTypes.SET_CLOUDINARY_CREDS,
        creds
    }

}