import { Component } from '@angular/core';
import { AppService } from './app.component.service';
import { Store } from '@ngrx/store';
import { CLOUDINARY_API_BASE, CLOUDINARY_BASE_URL, CLOUDINARY_IMAGE, CLOUDINARY_VIDEO } from './global/global';
import { setCloudinaryCred } from './NgRx/actions/posts.action';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Admin';
  constructor(
    public service: AppService,
    private store: Store
    ) {}


  ngOnInit(){
    this.getCloudinaryCreds();
  }

getCloudinaryCreds(){
  this.service.getCludinaryCredsApi()
  .subscribe((res)=>{
    console.log('res: ', res);
    let cloudinaryCreds = res.data;

    //Inserting Dynamic Links to Ngrx Store
    const IMAGE_LINK = `${CLOUDINARY_BASE_URL}${cloudinaryCreds.cloudName}${CLOUDINARY_IMAGE}`
    const IMAGE_BASE_LINK = `${CLOUDINARY_API_BASE}${cloudinaryCreds.cloudName}${CLOUDINARY_IMAGE}`
    const VIDEO_LINK = `${CLOUDINARY_BASE_URL}${cloudinaryCreds.cloudName}${CLOUDINARY_VIDEO}`
    const VIDEO_BASE_LINK = `${CLOUDINARY_API_BASE}${cloudinaryCreds.cloudName}${CLOUDINARY_VIDEO}`

    cloudinaryCreds = {
      ...cloudinaryCreds,
      IMAGE_LINK,
      IMAGE_BASE_LINK,
      VIDEO_LINK,
      VIDEO_BASE_LINK
    }
    this.store.dispatch(setCloudinaryCred(cloudinaryCreds))

  })
  }

}