// python api
export const Url = 'https://apisuperapp-staging.eazy-online.com/leoffer/python/';
// python upgrade api Url
export const UrlLink = 'https://apisuperapp-staging.eazy-online.com/leoffer/v1/python/';

export const UrlTag = 'https://apisuperapp-staging.eazy-online.com/leoffer/v1/admin/';
//  node api
export const devUrl = 'https://apisuperapp-staging.eazy-online.com/leoffer/v1/';
//  email api
export const emailUrl = 'https://apisuperapp-staging.eazy-online.com/v1/'; // 'https://emailapi.le-offers.com/'
// sms api
export const smsUrl = 'https://smsapi.le-offers.com/';
// wallet url
export const walletUrl = 'https://apisuperapp-staging.eazy-online.com/v1/';

// new python api for categories
export const Url2 = 'https://apisuperapp-staging.eazy-online.com/leoffer/v2/';

// analytics url
export const AnalyticsUrl = 'https://analytics.eazy-online.com/dashboard';


//Making it Dynamic using /clodinary API
export const CLOUDINARY_BASE_URL ="https://res.cloudinary.com/"
export const CLOUDINARY_IMAGE = "/image/upload/"
export const CLOUDINARY_VIDEO ="/video/upload/"
export const CLOUDINARY_API_BASE = "https://api.cloudinary.com/v1_1/"
